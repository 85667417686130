body{
  background-color: black;
  height: 100%;
  margin: 0;
  width: 100%;
  display: block;
}

.welcome-bar{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 20px;
  height: 50px;
  background: white;
  box-shadow: 0 1.5px 3px #ddd, 0 1.5px 3px #ddd;
}

.flex-within-welcome{
  display: flex;
  width: 100%;
  height: 100%;
}

.flex-within-welcome p{
  color: black;
  text-align: left;
  margin-left: 70px;
  line-height: 15px;
  font-size: 17px;
  font-weight: 600;
  text-shadow: #ddd;
}

.flex-within-welcome .user-icon{
  width: 35px;
  height: 35px;
  margin-top: 7.5px;
  margin-left: 20px;
  outline: none;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border:none;
  cursor: pointer;
}

.user-icon .green-dot{
  outline:none;
  background-color: transparent;
  width: 8px;
  height: 8px;
  margin-top: 25px;
  margin-left: 27px;
  position: absolute;
  display: block;
  
  border: 1px solid #ddd;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}



.after-container{
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  background: transparent;
  
  height: auto;
}

.after-container .left-side{
  background-color: white;
  box-shadow: 0 1.5px 3px #ddd, 0 1.5px 3px #ddd;
  display: inline-block;
  width: 55%;
  margin: 0;
  height: 870px;
  border-radius: 20px;
}
.after-container .header-flex{
  display: flex;
  width: 100%;
  height: 80px;
}
.after-container .left-side h1{
  font-size: 25px;
  color: black;
  font-weight: 500;
  text-align: left;
 line-height: 80px;
 margin-left: 50px;
}

.after-container .confirm-pay-btn{
 width: 120px;
 height: 40px;
 margin-top: 40px;
 border: none;
 text-decoration: none;
 text-decoration-color: none;
 display: block;
 line-height: 35px;
 border: 1px solid #ddd;
 color: #ddd;
 margin-left: auto;
 margin-right: 40px;
 background-color: #023020;
 border-radius: 7px;
 font-size: 13px;
 cursor: pointer;
}



.after-container .left-side p{
  font-size: 20px;
  color: black;
  text-align: left;
  font-weight: 525;
  margin-left: 80px;
  margin-bottom: 2px;
  margin-top: 20px;
}

.left-side .wallet-ad-container{
  margin-left: 80px;
  width: 80%;
  height: 40px;
  background-color: whitesmoke;
  border: 1px solid #ddd;
  border-radius: 5px;
  line-height: 40px;
  text-align: left;
  display: block;

}

.left-side .wallet-ad-container p{
  margin-left: 20px;
  color: gray;
  font-size: 16px !important;
  margin-top: 0;
}

.left-side .copy-button{
  margin-left: 80px;
  color: black;
  font-size: 18px;
  margin-top: 0;
  background-color: whitesmoke;
  border-radius: 7px;
  border: 1px solid #ddd;
  width: 90px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
  line-height: 40px;
  text-decoration: none;
  text-decoration-color: none;
}


.after-container .left-side .hr{
  width: 80%;
  margin-left: 50px;
  margin-top: 20px;
  height: 0.5px !important;
  border: none;
  background-color: black;
}

.after-container .left-side .qrcode{
  width: 220px;
  height: auto;
  background-repeat: no repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #ddd;
  display: block;
  margin-left: 80px;
  margin-top: 0px;
}

.after-container .left-side .p{
  font-size: 14px;
  font-weight: 500;
  margin-left: 80px;
  margin-top: 0;
  color: black;
  opacity: 0.8;
}

.after-container .left-side .p-bold{
  font-size: 14.5px;
  font-weight: 500;
  margin-left: 80px;
  margin-top: 0;
  font-weight: 650;
  opacity:0.9;
  color:#023020;
}



.after-container .right-side{
  background-color: white;
  box-shadow: 0 1.5px 3px #ddd, 0 1.5px 3px #ddd;
  display: block;
  width: 42.5%;
  margin-left: 2.5% !important;
  border-radius: 10px;
  margin: 0;
  height: fit-content;
}

.after-container .right-side h1{
  font-size: 25px;
  color: black;
  font-weight: 500;
  text-align: center;
  line-height: 80px;
  margin-bottom: 0;
  margin-left: 0;
}

.after-container .right-side .p{
  font-size: 17px;
  color: black;
  font-weight: 500;
  text-align: center;
  margin-top: 0;
}


.after-container .right-side .overflow-cont{
  width: 100%;
  height: auto;
  margin: 0;
  overflow-x: scroll;
  height: fit-content;
  display: block;

}
.after-container .right-side .topic-cont{
  display: flex;
  width: 1350px !important;
  height: 60px;
  margin: 0;
  margin-left: 20px;
  border-bottom: 2px solid #ddd;
}

.right-side .topic-cont .topics{
  height: 100%;
  width: 250px;
  display: block;
  text-align: center;
  margin-left: 20px;
  color: gray;
  font-weight: 550;
  line-height: 60px;


}


.after-container .transact-cont{
  display: flex;
  width: 1350px !important;
  height: 80px;
  margin: 0;
  margin-left: 20px;
  border-bottom: 1px solid #ddd;
}

.right-side .transact{
  height: 100%;
  width: 250px;
  display: block;
  text-align: center;
  margin-left: 20px;
  color: gray;
  line-height: 80px;
  font-size: 15px;
  font-weight:700 !important;
}

.after-container .transact-cont .status{
  width: 90px;
  height: 40px;
  margin-top: 20px;
  text-align: center;
  display: block;
  line-height: 40px;
  border: 1px solid #ddd;
  color: black;
  margin-left: auto;
  margin-right: auto;
  background-color: whitesmoke;
  border-radius: 7px;
  font-size: 13px;

}


@media screen and (max-width: 1100px) {
  .after-container{
    display: block;
  }
  
  .after-container .left-side{
    display: block;
    width: 100%;
  }

  .after-container .right-side{
    display: block;
    width: 100%;
    margin-left:0 !important;
  }

  
}

@media screen and (max-width: 600px) {

  .welcome-bar{
    width: 95%;
    margin-top: 10px;
  }
  .after-container{
    margin-top: 15px;
    width: 95%;
  }
  
  .after-container .left-side{
   border-radius: 5px;
  }


  .after-container .header-flex{
    width: 100%;
    height: 60px;
  }
  .after-container .left-side h1{
    font-size: 20px;
    text-align: left;
   line-height: 60px;
   margin-left: 20px;
  }
  
  .after-container .confirm-pay-btn{
   width: 107px;
   height: 34px;
   margin-top: 30px;
   line-height: 30px;
   margin-right: 20px;
   font-size: 12px;
  }

  .after-container .right-side{
    border-radius: 5px;
    margin-top: 0;
  }

  .after-container .left-side p{
    margin-left: 40px;
    font-size: 18px;
  }


  .after-container .left-side .p{
    font-size: 13.5px;
    margin-left: 40px;
    margin-right: 30px;
  }
  
  .after-container .left-side .p-bold{
    font-size: 13.5px;
    margin-left: 40px;
  }

  .left-side .wallet-ad-container{
    margin-left: 40px;
    width: 90%;
    height: 32px;
    line-height: 32px;
  }
  
  .left-side .wallet-ad-container p{
    font-size: 14px !important;
  }
  
  .left-side .copy-button{
    margin-left: 40px;
    font-size: 16.5px;
    border-radius: 4px;
    width: 80px;
    height: 30px;
    font-size: 14.3px;
    line-height: 30px;
  }
  
  
  .after-container .left-side .hr{
    width: 90%;
    margin-left: 20px;
  }
  
  .after-container .left-side .qrcode{
    width: 220px;
    margin-left: 40px;
  }

  .after-container .right-side h1{
    font-size: 20px;
    line-height: 70px;
  }
  
  .after-container .right-side .p{
    font-size: 16px;
    opacity: 0.8;
  }





  .after-container .right-side .topic-cont{
    display: flex;
    width: 950px !important;
    height: 40px;
    margin: 0;
    margin-left: 0px;
    border-bottom: 2px solid #ddd;
  }
  
  .right-side .topic-cont .topics{
    height: 100%;
    width: 190px;
    display: block;
    text-align: center;
    margin-left: 0;
    color: gray;
    font-size: 14px;
    font-weight: 550;
    line-height: 40px;
  
  
  }
  
  
  .after-container .transact-cont{
    display: flex;
    width: 950px !important;
    height: 50px;
    margin: 0;
    margin-left: 0;
    border-bottom: 1px solid #ddd;
  }
  
  .right-side .transact{
    height: 100%;
    width: 190px;
    display: block;
    text-align: center;
    margin-left: 0;
    color: gray;
    font-size: 12px;
    font-weight: 700 !important;
    line-height: 50px;
  }
  
  .after-container .transact-cont .status{
    width: 70px;
    height: 20px;
    margin-top: 15px;
    text-align: center;
    display: block;
    line-height: 20px;
    border: 1px solid #ddd;
    color: black;
    margin-left: auto;
    margin-right: auto;
    background-color: whitesmoke;
    border-radius: 3px;
    font-size: 11px !important;
  
  }
  

  
}

@media screen and (max-width: 450px) {
  .after-container .header-flex{
    height: 90px;
  }
  .after-container .confirm-pay-btn{
   margin-top: 60px;
   line-height: 30px;
   right: 10px;
   position: absolute;
  }

  .after-container .left-side p{
    margin-left: 20px;
  }


  .after-container .left-side .p{
    margin-left: 20px;
  }
  
  .after-container .left-side .p-bold{
    margin-left: 20px;
  }

  .left-side .wallet-ad-container{
    margin-left: 10px;
    width: 90%;
    overflow-x: scroll;
  }

  .left-side .copy-button{
    margin-left: 20px;
  }
  
  
  .after-container .left-side .hr{
    width: 93%;
    margin-left: 10px;
  }
  
  .after-container .left-side .qrcode{
    margin-left: 20px;
  }

  .after-container .left-side h1{
    font-size: 20px;
    text-align: left;
   line-height: 50px;
   margin-left: 10px;
  }
}
